import React from "react"

export const INDEX_NAME = "embedded_listings_production"
export const ALGOLIA_APP_ID = "VNWTL20JMV"

export const SORT_OPTIONS = [
  {
    label: "Time: ending soonest",
    value: "embedded_listings_production_end_time_asc",
  },
  {
    label: "Time: newly listed",
    value: "embedded_listings_production_end_time_desc",
  },
  {
    label: "Price: lowest first",
    value: "embedded_listings_production_current_price_asc",
  },
  {
    label: "Price: highest first",
    value: "embedded_listings_production_current_price_desc",
  },
  {
    label: "Bids: fewest first",
    value: "embedded_listings_production_bid_count_asc",
  },
  {
    label: "Bids: most first",
    value: "embedded_listings_production_bid_count_desc",
  },
]

export interface Settings {
  embedKey: string
  headerContent?: string
  brandColor?: string
  defaultSort?: string
  supplierFilter?: "true" | "false"
  priceSlider?: "true" | "false"
  pickupOnlyFilter?: "true" | "false"
  formatFilter?: "true" | "false"
  cssOverrides?: string
  locationFilter?: "true" | "false"
  // leaving out category filter here since the UI looks bad without it
}

const defaultSettings: Settings = {
  // This is the key you get from Lister > Admin > Settings > Embedded Listings
  embedKey: "",

  // Can override text context of the page
  headerContent: "Stop looking for an item -- find it!",

  // Change the default sorting, default=Time ending soonest
  defaultSort: "Time: ending soonest",

  // Themeing colors
  brandColor: `#2AABE2`,

  // Show/hide filters from the UI. Important these stay as strings since query params work on strings only
  formatFilter: "true",
  supplierFilter: "true",
  locationFilter: "false",
  priceSlider: "true",
  pickupOnlyFilter: "true",
  cssOverrides: "",
}

export default defaultSettings
