import { Duration, format, fromUnixTime, getUnixTime, intervalToDuration } from "date-fns"
import * as React from "react"

// ALL IN SECONDS
const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

const dateFormatted = (d: Date) => {
  const currDay = format(new Date(), "ccc")
  return `left (${format(d, "ccc, hh:mm a")})`.replace(currDay, "Today")
}

/**
 * Formats the end time for an auction listing and auto updates the time every second
 * more than a day = 3d 4h left (Sun, 04:52 PM)
 * less than a day (in bold) = 4h 12m left (Today 05:23 PM)
 * less than an hour (in red) = 3m left (Today 01:13 PM)
 * less than a minute (in red) = 44s left (Today 01:13 PM)
 */
const TimeLeft = ({ unixTimestamp }: { unixTimestamp: number }) => {
  const ts = fromUnixTime(unixTimestamp)
  const now = new Date()
  const diff = unixTimestamp - getUnixTime(now)
  const [durationLeft, setDurationLeft] = React.useState<Duration>(intervalToDuration({ start: ts, end: now }))

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDurationLeft(intervalToDuration({ start: ts, end: new Date() }))
    }, 1000)

    return () => clearTimeout(timer)
  }, [ts])

  if (diff <= 0) {
    return <span>Ended</span>
  }

  if (diff <= MINUTE) {
    return (
      <span className="time-left-urgent">
        {durationLeft.seconds}s {dateFormatted(ts)}
      </span>
    )
  }

  if (diff <= HOUR) {
    return (
      <span className="time-left-urgent">
        {durationLeft.minutes}m {dateFormatted(ts)}
      </span>
    )
  }

  if (diff <= DAY) {
    return (
      <span className="time-left-notice">
        {durationLeft.hours}h {durationLeft.minutes}m {dateFormatted(ts)}
      </span>
    )
  }

  return (
    <span>
      {durationLeft.days}d {durationLeft.hours}h {dateFormatted(ts)}
    </span>
  )
}

export default TimeLeft
