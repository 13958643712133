import { SORT_OPTIONS } from "./settings"

export function formatNumber(value): string {
  return Number(value).toLocaleString()
}

export function formatMoney(num: number): string {
  return `$${num.toFixed(2)}`
}

/**
 *
 * @param int
 * @returns
 */
export function pluralize(int: number): string {
  if (int > 1 || int === 0) return "s"
}

export function getSortOptionFromSettings(opt: string) {
  const selected = SORT_OPTIONS.find((s) => s.label === opt)
  if (selected) {
    return selected
  }

  return SORT_OPTIONS[0]
}

export function safeBase64Decode(s: string) {
  try {
    return atob(s)
  } catch (error) {
    return undefined
  }
}
