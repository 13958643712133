import * as React from "react"
import { Highlight } from "react-instantsearch-dom"
import TimeLeft from "./TimeLeft"
import { IHit } from "./types"
import { formatMoney, pluralize } from "./utils"

const Hit = ({ hit }: { hit: IHit }) => {
  const activeListing = hit.active_listings[0]
  return (
    <a target="_blank" href={activeListing.url} rel="noreferrer" className="hit-link">
      <article className="hit">
        <header className="hit-image-container">
          <img src={hit.image_url} alt={hit.title} className="hit-image" />
        </header>

        <div className="hit-info-container">
          <h1 className="hit-title">
            <Highlight attribute="title" tagName="mark" hit={hit} />
          </h1>

          <footer className="hit-footer">
            <p className="hit-pricing">{formatMoney(activeListing.current_price || activeListing.start_price)}</p>
            <p className="hit-bidding-info">
              {activeListing.listing_type === "fixed_price" ? (
                <span>Buy It Now</span>
              ) : (
                <>
                  {activeListing.bid_count} bid{pluralize(activeListing.bid_count)}
                  {" • "}
                  {activeListing.end_time && <TimeLeft unixTimestamp={activeListing.end_time} />}
                </>
              )}
              {hit.pickup_only && (
                <>
                  <br />
                  Free Local Pickup
                </>
              )}
            </p>
          </footer>
        </div>
      </article>
    </a>
  )
}

export default Hit
