import queryString from "query-string"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import EmbeddingError from "./EmbeddingError"
import defaultSettings from "./settings"
import "./style/App.css"
import "./style/App.mobile.css"
import "./style/Pagination.css"
import "./style/Theme.css"
import { safeBase64Decode } from "./utils"

function renderError(message) {
  ReactDOM.render(<EmbeddingError message={message} />, document.getElementById("root"))
}

function boot() {
  let qs = {}
  try {
    qs = queryString.parse(window.location.search)
  } catch (error) {
    console.error(error)
    return renderError("Unable to parse embedded listings settings")
  }

  // Override default settings with user supplied settings
  const settings = { ...defaultSettings, ...qs }
  document.documentElement.style.setProperty("--brand-color", settings.brandColor)

  if (!settings.embedKey) {
    return renderError(
      "An embedKey must be provided. Go to https://app.uprightlabs.com/admin/embedded_listings to retrieve one."
    )
  }

  if (settings.cssOverrides) {
    const decoded = safeBase64Decode(settings.cssOverrides)
    if (!decoded) {
      return renderError("Unable to decode cssOverrides. Please make sure cssOverrides is valid Base64")
    }

    const overrides = document.createElement("style")
    overrides.innerHTML = decoded
    document.head.appendChild(overrides)
  }

  ReactDOM.render(
    <React.StrictMode>
      <App settings={settings}></App>
    </React.StrictMode>,
    document.getElementById("root")
  )
}

boot()
