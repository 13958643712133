import * as React from "react"

export default ({ message }) => {
  return (
    <div style={{ textAlign: "center", margin: "40px auto" }}>
      <h1>Error!</h1>
      <p>{message}</p>
    </div>
  )
}
