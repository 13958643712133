import algoliasearch from "algoliasearch/lite"
import React, { useRef } from "react"
import {
  ClearRefinements,
  HierarchicalMenu,
  Hits,
  HitsPerPage,
  InstantSearch,
  Pagination,
  Panel,
  RefinementList,
  SearchBox,
  SortBy,
  ToggleRefinement,
} from "react-instantsearch-dom"
import Hit from "./Hit"
import { ALGOLIA_APP_ID, INDEX_NAME, Settings, SORT_OPTIONS } from "./settings"
import { getSortOptionFromSettings } from "./utils"
import { ClearFiltersMobile, NoResults, PriceSlider, ResultsNumberMobile, SaveFiltersMobile } from "./widgets"

interface Props {
  settings: Settings

  // These get injected into <App> via Algolia contexts
  searchState?: any
  createURL?: any
  onSearchStateChange?: any
}

const App = (props: Props) => {
  const containerRef = useRef(null)
  const headerRef = useRef(null)
  const searchClient = algoliasearch(ALGOLIA_APP_ID, props.settings.embedKey)

  function openFilters() {
    document.body.classList.add("filtering")
    window.scrollTo(0, 0)
    window.addEventListener("keyup", onKeyUp)
    window.addEventListener("click", onClick)
  }

  function closeFilters() {
    document.body.classList.remove("filtering")
    containerRef.current.scrollIntoView()
    window.removeEventListener("keyup", onKeyUp)
    window.removeEventListener("click", onClick)
  }

  function onKeyUp(event) {
    if (event.key !== "Escape") {
      return
    }

    closeFilters()
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return
    }

    closeFilters()
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={INDEX_NAME}
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      <header className="header" ref={headerRef}>
        <p className="header-title">{props.settings.headerContent}</p>

        <SearchBox
          translations={{
            placeholder: "Search for anything",
          }}
          submit={
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18">
              <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
              </g>
            </svg>
          }
        />
      </header>

      <main className="container" ref={containerRef}>
        <div className="container-wrapper">
          <section className="container-filters" onKeyUp={onKeyUp}>
            <div className="container-header">
              <h2>Filters</h2>

              <div className="clear-filters" data-layout="desktop">
                <ClearRefinements
                  translations={{
                    reset: (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                          <g fill="none" fillRule="evenodd" opacity=".4">
                            <path d="M0 0h11v11H0z" />
                            <path
                              fill="#000"
                              fillRule="nonzero"
                              d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                            />
                          </g>
                        </svg>
                        Clear filters
                      </>
                    ),
                  }}
                />
              </div>

              <div className="clear-filters" data-layout="mobile">
                <ResultsNumberMobile />
              </div>
            </div>

            <div className="container-body">
              <Panel header="Category">
                <HierarchicalMenu
                  attributes={[
                    "categories.lvl0",
                    "categories.lvl1",
                    "categories.lvl2",
                    "categories.lvl3",
                    "categories.lvl4",
                  ]}
                />
              </Panel>

              {props.settings.formatFilter === "true" && (
                <Panel header="Format">
                  <RefinementList
                    attribute="active_listings.listing_type"
                    searchable={false}
                    transformItems={(items) => {
                      return items.map((i) => {
                        if (i.label === "fixed_price") return { ...i, label: "Buy It Now" }
                        return { ...i, label: "Auction" }
                      })
                    }}
                  />
                </Panel>
              )}

              {props.settings.supplierFilter === "true" && (
                <Panel header="Supplier">
                  <RefinementList
                    attribute="supplier_name"
                    searchable={true}
                    translations={{
                      placeholder: "Search for stores",
                    }}
                  />
                </Panel>
              )}

              {props.settings.locationFilter === "true" && (
                <Panel header="Location Hub">
                  <RefinementList
                    attribute="location_hub"
                    searchable={true}
                    translations={{
                      placeholder: "Search by location",
                    }}
                  />
                </Panel>
              )}

              {props.settings.priceSlider === "true" && (
                <Panel header="Price">
                  <PriceSlider min={0} attribute="active_listings.current_price" />
                </Panel>
              )}

              {props.settings.pickupOnlyFilter === "true" && (
                <Panel header="Pickup only">
                  <ToggleRefinement attribute="pickup_only" label="Filter pickup only items" value={true} />
                </Panel>
              )}
            </div>
          </section>

          <footer className="container-filters-footer" data-layout="mobile">
            <div className="container-filters-footer-button-wrapper">
              <ClearFiltersMobile containerRef={containerRef} />
            </div>

            <div className="container-filters-footer-button-wrapper">
              <SaveFiltersMobile onClick={closeFilters} />
            </div>
          </footer>
        </div>

        <section className="container-results">
          <header className="container-header container-options">
            <SortBy
              className="container-option"
              defaultRefinement={getSortOptionFromSettings(props.settings.defaultSort).value}
              items={SORT_OPTIONS}
            />

            <HitsPerPage
              className="container-option"
              items={[
                {
                  label: "20 results per page",
                  value: 20,
                },
                {
                  label: "50 results per page",
                  value: 50,
                },
                {
                  label: "100 results per page",
                  value: 100,
                },
              ]}
              defaultRefinement={20}
            />
          </header>

          <Hits hitComponent={Hit} />
          <NoResults />

          <footer className="container-footer">
            <Pagination
              padding={2}
              showFirst={false}
              showLast={false}
              translations={{
                previous: (
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M9 5H1M5 9L1 5l4-4" />
                    </g>
                  </svg>
                ),
                next: (
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M1 5h8M5 9l4-4-4-4" />
                    </g>
                  </svg>
                ),
              }}
            />
          </footer>
        </section>
      </main>

      <aside data-layout="mobile">
        <button className="filters-button" data-action="open-overlay" onClick={openFilters}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              strokeWidth="1.29"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filters
        </button>
      </aside>
    </InstantSearch>
  )
}

export default App
